import React from 'react';
import FastingBenefitsTracker from './components/FastingBenefitsTracker';
import './styles/custom.css';

function App() {
  return (
    <div className="App futuristic-background">
      <div className="scan-line"></div>
      <FastingBenefitsTracker />
    </div>
  );
}

export default App;