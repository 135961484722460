export const hourlyTips = [
  "Stay hydrated! Drink water regularly throughout your fast.",
  "If you feel hungry, try going for a short walk to distract yourself.",
  "Practice mindfulness or meditation to stay focused on your fasting goals.",
  "Remember why you started this fasting journey. You're making great progress!",
  "Avoid looking at food-related content to reduce cravings.",
  "Try some light stretching or yoga to keep your body active.",
  "Listen to a podcast or audiobook to keep your mind engaged.",
  "Reflect on the positive changes you're experiencing during your fast.",
  "If you're struggling, reach out to a fasting buddy or support group for encouragement.",
  "Visualize the health benefits you're gaining with each passing hour.",
  "Take a moment to practice deep breathing exercises.",
  "Focus on non-food related activities you enjoy.",
  "Remember, hunger often comes in waves. It will pass.",
  "Stay busy with work, hobbies, or social activities to distract from hunger.",
  "Brush your teeth if you're experiencing cravings.",
  "Remind yourself of your fasting goals and why they're important to you.",
  "Try a new herbal tea to keep your taste buds satisfied.",
  "Take a power nap if you're feeling low on energy.",
  "Practice gratitude by listing things you're thankful for.",
  "Engage in light exercise like walking or gentle yoga.",
  "Avoid social media if it triggers food cravings.",
  "Remind yourself that fasting is a practice in self-discipline.",
  "Take progress photos or measurements to track your journey.",
  "Explore new hobbies that keep your hands and mind busy.",
  "Visualize yourself successfully completing your fast.",
  "Try oil pulling with coconut oil to freshen your mouth and reduce cravings.",
  "Plan a healthy meal for when your fast ends.",
  "Read success stories from others who have completed similar fasts.",
  "Practice positive self-talk and affirmations.",
  "Take a relaxing bath or shower to pamper yourself.",
  "Try facial exercises or gua sha for a lymphatic massage.",
  "Write in a journal about your fasting experience.",
  "Do some light cleaning or organizing to stay active.",
  "Practice good posture throughout the day.",
  "Try dry brushing to stimulate your lymphatic system.",
  "Engage in a creative activity like drawing or coloring.",
  "Learn a new skill or language to keep your mind occupied.",
  "Do some gentle stretching to relieve any tension.",
  "Practice mindful breathing for a few minutes.",
  "Remind yourself of how far you've come in your fast.",
  "Try aromatherapy with essential oils to boost your mood.",
  "Take a moment to check in with your body and how it feels.",
  "Visualize the cellular repair happening in your body.",
  "Plan some exciting, non-food related activities for the near future.",
  "Try a new type of calorie-free sparkling water for variety.",
  "Do a quick facial massage to promote relaxation.",
  "Engage in a hobby that requires focus and concentration.",
  "Practice balancing exercises to improve your stability.",
  "Remind yourself that you're strengthening your willpower with each passing hour.",
  "Try tongue scraping to freshen your mouth and reduce cravings.",
  "Take a few minutes to practice progressive muscle relaxation.",
  "Visualize your goals and the person you're becoming through this fast.",
  "Try some desk exercises if you're at work.",
  "Engage in a short meditation focused on your breath.",
  "Remind yourself of the potential health benefits you're gaining.",
  "Try oil pulling with sesame oil for oral health.",
  "Take a moment to practice gratitude for your body's resilience.",
  "Engage in a quick decluttering session in your living space.",
  "Try some facial yoga exercises.",
  "Visualize your body efficiently burning stored fat for energy.",
  "Practice some hand and wrist stretches, especially if you work at a computer.",
  "Remind yourself that fasting is a form of self-care.",
  "Try a new herbal tea blend for variety.",
  "Take a moment to focus on your posture and adjust if needed.",
  "Engage in a short breathing exercise to center yourself.",
  "Visualize the positive changes happening in your body at a cellular level.",
  "Try some gentle neck and shoulder rolls to relieve tension.",
  "Remind yourself of your strength and determination.",
  "Take a moment to appreciate the mental clarity that often comes with fasting.",
  "Try a new type of sugar-free gum if you're struggling with cravings.",
  "Engage in a quick session of jumping jacks or other light cardio.",
  "Visualize successfully completing your fast and how you'll feel.",
  "Remind yourself that you're developing a stronger relationship with your body."
];

export const getCurrentHourTip = (hour) => {
  return hourlyTips[hour % hourlyTips.length];
};