export const milestones = [
  {
    hour: 12,
    title: "Metabolic switch to fat burning",
    description: "Your body has depleted its glycogen stores and is now primarily burning fat for energy. This metabolic switch, known as ketosis, can lead to improved insulin sensitivity and increased fat loss.\n\nStudies have shown that this transition typically occurs between 12-36 hours of fasting, depending on your activity level and diet prior to the fast."
  },
  {
    hour: 24,
    title: "Peak autophagy",
    description: "Autophagy, your body's cellular cleaning process, reaches its peak. This process helps remove damaged proteins and organelles, potentially reducing the risk of several diseases, including cancer and neurodegeneration.\n\nA 2010 study by Alirezaei et al. showed that short-term fasting in mice led to a dramatic upregulation of autophagy in brain cells."
  },
  {
    hour: 36,
    title: "Significant reduction in inflammation",
    description: "By this point, your body has significantly reduced inflammation markers. A study published in Cell Stem Cell in 2014 found that prolonged fasting reduces enzymatic activity of PKA, a key inflammatory pathway.\n\nThis reduction in inflammation can have wide-ranging benefits, from improved cardiovascular health to reduced pain in chronic conditions."
  },
  {
    hour: 48,
    title: "Increased stem cell production",
    description: "Your body significantly increases the production of stem cells. A 2014 study in the journal Cell Stem Cell found that prolonged fasting forces the body to use stores of glucose, fat, and ketones, and also breaks down a significant portion of white blood cells.\n\nThis depletion of white blood cells induces changes that trigger stem cell-based regeneration of new immune system cells."
  },
  {
    hour: 72,
    title: "Immune system reset",
    description: "By this point, your body has potentially completed a full immune system reset. The same 2014 study mentioned earlier found that a 72-hour fast resulted in the regeneration of a significant portion of the immune system.\n\nThis 'reset' can be particularly beneficial for those with compromised immune systems or autoimmune disorders. The study's author, Valter Longo, stated that fasting 'flips a regenerative switch' in the body."
  }
];

export const getCurrentMilestone = (currentHour) => {
  return milestones.filter(milestone => milestone.hour <= currentHour).pop() || null;
};

export const getNextMilestone = (currentHour) => {
  return milestones.find(milestone => milestone.hour > currentHour) || milestones[milestones.length - 1];
};