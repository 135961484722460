//fasttrackercode

import React, { useState, useEffect } from "react";
import { Card, CardContent, CardHeader, CardTitle } from "./ui/card";
import { Input } from "./ui/input";
import { Button } from "./ui/button";
import { hourlyBenefits } from "../data/hourlyBenefits";
import { getCurrentHourTip } from "../data/hourlyTips";
import { getNextMilestone, getCurrentMilestone } from "../data/milestones";
import Lottie from "lottie-react";
import dnaWaveAnimation from "../animations/dnaWave.json";

const BenefitIcon = () => (
  <svg
    className="icon-benefit"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M20 6H17V4C17 2.89 16.11 2 15 2H9C7.89 2 7 2.89 7 4V6H4C2.89 6 2 6.89 2 8V19C2 20.11 2.89 21 4 21H20C21.11 21 22 20.11 22 19V8C22 6.89 21.11 6 20 6ZM9 4H15V6H9V4ZM20 19H4V8H20V19Z" />
    <path d="M11 11H13V14H16V16H13V19H11V16H8V14H11V11Z" />
  </svg>
);

const MilestoneIcon = () => (
  <svg
    className="icon-milestone"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 2L1 21H23L12 2ZM12 6L19.53 19H4.47L12 6Z" />
    <path d="M11 10V14H13V10H11ZM11 16V18H13V16H11Z" />
  </svg>
);

const NextIcon = () => (
  <svg
    className="icon-next"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
  </svg>
);

const TipIcon = () => (
  <svg
    className="icon-tip"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M9 21C9 21.55 9.45 22 10 22H14C14.55 22 15 21.55 15 21V20H9V21ZM12 2C7.03 2 3 6.03 3 11C3 14.03 4.53 16.82 7 18.47V19C7 19.55 7.45 20 8 20H16C16.55 20 17 19.55 17 19V18.47C19.47 16.82 21 14.03 21 11C21 6.03 16.97 2 12 2ZM14.5 14.19L12 15.69L9.5 14.19V12.69L12 14.19L14.5 12.69V14.19ZM15 11H9V9H15V11Z" />
  </svg>
);

const EditIcon = () => (
  <svg
    className="icon-edit"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 17.25V21H6.75L17.81 9.94L14.06 6.19L3 17.25ZM20.71 7.04C21.1 6.65 21.1 6.02 20.71 5.63L18.37 3.29C17.98 2.9 17.35 2.9 16.96 3.29L15.13 5.12L18.88 8.87L20.71 7.04Z"
      fill="#60a5fa"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    className="icon-menu"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M3 18H21V16H3V18ZM3 13H21V11H3V13ZM3 6V8H21V6H3Z" fill="#ffffff" />
  </svg>
);

const APP_VERSION = "1.0.1"; // Increment this when you make significant changes

const FastingBenefitsTracker = () => {
  const [startTime, setStartTime] = useState(null);
  const [elapsedTime, setElapsedTime] = useState(0);
  const [inputDate, setInputDate] = useState("");
  const [inputTime, setInputTime] = useState("");
  const [currentBenefit, setCurrentBenefit] = useState(hourlyBenefits[0]);
  const [currentMilestone, setCurrentMilestone] = useState(
    getCurrentMilestone(0),
  );
  const [nextMilestone, setNextMilestone] = useState(getNextMilestone(0));
  const [fastingGoal, setFastingGoal] = useState(72); // Default to 72 hours
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const storedVersion = localStorage.getItem("appVersion");
    if (storedVersion !== APP_VERSION) {
      // Clear only the relevant data
      localStorage.removeItem("fastingStartTime");
      localStorage.removeItem("fastingGoal");
      // Update the stored version
      localStorage.setItem("appVersion", APP_VERSION);
    }

    const savedStartTime = localStorage.getItem("fastingStartTime");
    const savedFastingGoal = localStorage.getItem("fastingGoal");

    const now = new Date();
    setInputDate(now.toISOString().slice(0, 10)); // YYYY-MM-DD
    setInputTime(now.toTimeString().slice(0, 5)); // HH:MM

    if (savedStartTime) {
      const parsedStartTime = parseInt(savedStartTime, 10);
      if (parsedStartTime <= Date.now()) {
        setStartTime(parsedStartTime);
        setElapsedTime(Date.now() - parsedStartTime);
      } else {
        // If the start time is in the future, reset it
        localStorage.removeItem("fastingStartTime");
      }
    }

    if (savedFastingGoal) {
      setFastingGoal(parseInt(savedFastingGoal, 10));
    }
  }, []);

  const handleStart = () => {
    const dateTimeString = `${inputDate}T${inputTime}:00`;
    const newStartTime = new Date(dateTimeString).getTime();
    setStartTime(newStartTime);
    setElapsedTime(0);
    localStorage.setItem("fastingStartTime", newStartTime.toString());
    localStorage.setItem("fastingGoal", fastingGoal.toString());
    setIsEditing(false);
  };

  const handleEdit = () => {
    const dateToUse = startTime ? new Date(startTime) : new Date();
    setInputDate(dateToUse.toISOString().slice(0, 10)); // YYYY-MM-DD
    setInputTime(dateToUse.toTimeString().slice(0, 5)); // HH:MM
    setIsEditing(true);
  };

  useEffect(() => {
    let interval;
    if (startTime) {
      interval = setInterval(() => {
        const now = Date.now();
        const timeDiff = now - startTime;
        setElapsedTime(timeDiff);

        const currentHour = Math.floor(timeDiff / 3600000);
        const newCurrentBenefit =
          hourlyBenefits.find((benefit) => benefit.hour === currentHour) ||
          currentBenefit;
        const newCurrentMilestone = getCurrentMilestone(currentHour);
        const newNextMilestone = getNextMilestone(currentHour);

        setCurrentBenefit(newCurrentBenefit);
        setCurrentMilestone(newCurrentMilestone);
        setNextMilestone(newNextMilestone);
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [startTime, currentBenefit]);

  const formatTime = (ms) => {
    const absMs = Math.abs(ms);
    const hours = Math.floor(absMs / 3600000);
    const minutes = Math.floor((absMs % 3600000) / 60000);
    return `${hours}h ${minutes}m`;
  };

  const progress = Math.max(
    0,
    Math.min(100, (elapsedTime / (fastingGoal * 3600000)) * 100),
  );

  return (
    <Card className="card">
      <CardHeader className="card-header">
        <CardTitle className="card-title">Fast Track</CardTitle>
        <Button onClick={handleEdit} className="menu-button">
          <MenuIcon />
        </Button>
      </CardHeader>
      <CardContent>
        {isEditing || !startTime ? (
          <div className="space-y-4">
            <Input
              type="date"
              value={inputDate}
              onChange={(e) => setInputDate(e.target.value)}
              max={new Date().toISOString().slice(0, 10)}
              className="input"
            />
            <Input
              type="time"
              value={inputTime}
              onChange={(e) => setInputTime(e.target.value)}
              className="input"
            />
            <select
              value={fastingGoal}
              onChange={(e) => setFastingGoal(parseInt(e.target.value, 10))}
              className="select"
            >
              <option value={24}>24 hours</option>
              <option value={48}>48 hours</option>
              <option value={72}>72 hours</option>
            </select>
            <Button
              onClick={handleStart}
              className="w-full bg-gradient-to-r from-blue-500 to-green-500 hover:from-blue-600 hover:to-green-600 text-white"
            >
              {startTime ? "Update" : "Start"} Tracking
            </Button>
          </div>
        ) : (
          <>
            <div className="text-center mb-6 relative">
              <div className="lottie-container">
                <Lottie
                  animationData={dnaWaveAnimation}
                  loop={true}
                  speed={0.5} // This will play the animation at half speed
                  rendererSettings={{
                    preserveAspectRatio: "xMidYMid slice",
                  }}
                  colorFilters={[
                    {
                      keypath: "**",
                      color: "#3b82f6",
                    },
                  ]}
                />
              </div>
              <div className="timer-container">
                <span className="timer glow-text">
                  {formatTime(elapsedTime)}
                </span>
              </div>
              <Button onClick={handleEdit} className="edit-button">
                <EditIcon className="icon-edit" />
              </Button>
            </div>
            <div className="progress-bar">
              <div
                className="progress-bar-fill"
                style={{ width: `${progress}%` }}
              />
            </div>
            <div className="text-center mb-4">
              <span className="font-semibold">Goal: {fastingGoal} hours</span>
            </div>
            <div className="space-y-4">
              <div className="info-card current-benefit">
                <h3>
                  <BenefitIcon /> Current benefit:
                </h3>
                {currentBenefit.message.split("\n").map((paragraph, index) => (
                  <p key={index}>{paragraph}</p>
                ))}
              </div>

              {currentMilestone && (
                <div className="info-card current-milestone">
                  <h3>
                    <MilestoneIcon /> Current milestone:
                  </h3>
                  <p className="subheading">{currentMilestone.title}</p>
                  {currentMilestone.description
                    .split("\n")
                    .map((paragraph, index) => (
                      <p key={index}>{paragraph}</p>
                    ))}
                </div>
              )}

              <div className="info-card next-milestone">
                <h3>
                  <NextIcon /> Next milestone
                </h3>
                <p className="highlight">
                  You're only{" "}
                  {formatTime(nextMilestone.hour * 3600000 - elapsedTime)} away
                  from:
                </p>
                <p className="subheading">{nextMilestone.title}</p>
                {nextMilestone.description
                  .split("\n")
                  .map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
              </div>

              <div className="info-card tip">
                <h3>
                  <TipIcon /> Tip of the hour:
                </h3>
                {getCurrentHourTip(Math.floor(elapsedTime / 3600000))
                  .split("\n")
                  .map((paragraph, index) => (
                    <p key={index}>{paragraph}</p>
                  ))}
              </div>
            </div>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default FastingBenefitsTracker;
