
export const hourlyBenefits = [
    { hour: 0, message: "Your fasting journey begins! Your body is transitioning from the fed state." },
    { hour: 1, message: "Insulin levels start to drop, signaling the body to begin using stored energy." },
    { hour: 2, message: "Your body begins to break down glycogen to maintain blood glucose levels." },
    { hour: 3, message: "Fat burning processes are gradually increasing." },
    { hour: 4, message: "Your body continues to deplete glucose stores." },
    { hour: 5, message: "Growth hormone levels begin to rise, promoting fat burning." },
    { hour: 6, message: "Glycogen stores in the liver are getting low, fat burning increases." },
    { hour: 7, message: "Ketone production is slowly beginning in the liver." },
    { hour: 8, message: "Fat burning continues to increase as insulin levels drop further." },
    { hour: 9, message: "Autophagy (cellular cleanup) processes are starting to activate." },
    { hour: 10, message: "Your body is adapting to using fat as its primary fuel source." },
    { hour: 11, message: "Growth hormone levels continue to rise, aiding in muscle preservation." },
    { hour: 12, message: "Ketone levels in your blood are rising, providing alternative fuel for your brain." },
    { hour: 13, message: "Fat burning is significantly increased compared to the fed state." },
    { hour: 14, message: "Autophagy continues to increase, clearing out damaged cellular components." },
    { hour: 15, message: "Your body is becoming more efficient at burning fat for fuel." },
    { hour: 16, message: "You've likely entered ketosis! Your body is primarily burning fat for energy." },
    { hour: 17, message: "Autophagy processes are in full swing, promoting cellular renewal." },
    { hour: 18, message: "Insulin sensitivity is improving in your muscle tissues." },
    { hour: 19, message: "Your body continues to adapt to using ketones for energy." },
    { hour: 20, message: "Growth hormone levels are significantly elevated, promoting tissue repair." },
    { hour: 21, message: "Fat oxidation rates have significantly increased." },
    { hour: 22, message: "Autophagy is providing potential longevity benefits." },
    { hour: 23, message: "Your body is becoming highly efficient at mobilizing and burning fat." },
    { hour: 24, message: "Congratulations on 24 hours! Autophagy is peaking, promoting cellular rejuvenation." },
    { hour: 25, message: "Your insulin sensitivity continues to improve." },
    { hour: 26, message: "Ketone production is steadily increasing, providing stable energy." },
    { hour: 27, message: "Your body is breaking down older, damaged proteins through autophagy." },
    { hour: 28, message: "Fat adaptation is improving, making fat burning more efficient." },
    { hour: 29, message: "Growth hormone levels remain elevated, supporting muscle preservation." },
    { hour: 30, message: "Autophagy continues at a high rate, deeply cleaning your cells." },
    { hour: 31, message: "Your body is becoming increasingly efficient at using ketones for fuel." },
    { hour: 32, message: "Inflammation markers in your body may be decreasing." },
    { hour: 33, message: "Cellular repair processes are in full swing." },
    { hour: 34, message: "Your body is breaking down visceral fat more efficiently." },
    { hour: 35, message: "Ketone levels in your blood continue to rise." },
    { hour: 36, message: "Growth hormone levels have increased up to 5 times the normal rate." },
    { hour: 37, message: "Your body is experiencing enhanced fat oxidation." },
    { hour: 38, message: "Autophagy is targeting damaged mitochondria for recycling." },
    { hour: 39, message: "Insulin sensitivity in your liver is improving." },
    { hour: 40, message: "Your body is efficiently breaking down stored fat for energy." },
    { hour: 41, message: "Cellular stress resistance is increasing." },
    { hour: 42, message: "Significant fat loss and ketone production are occurring." },
    { hour: 43, message: "Your body is recycling old immune cells." },
    { hour: 44, message: "Autophagy is helping to clear out potential cancer-causing damaged cells." },
    { hour: 45, message: "Growth hormone continues to promote muscle preservation and repair." },
    { hour: 46, message: "Your body is becoming highly adapted to using fat for fuel." },
    { hour: 47, message: "Inflammation levels in your body continue to decrease." },
    { hour: 48, message: "Incredible! Autophagy has peaked, and your cells are regenerating." },
    { hour: 49, message: "Your body is breaking down excess skin proteins." },
    { hour: 50, message: "Insulin has dropped to its lowest levels, maximizing fat burning." },
    { hour: 51, message: "Stem cell production is beginning to increase." },
    { hour: 52, message: "Your body is efficiently recycling damaged cellular components." },
    { hour: 53, message: "Ketone levels are providing maximum cognitive benefits." },
    { hour: 54, message: "Growth hormone levels continue to be significantly elevated." },
    { hour: 55, message: "Your body is experiencing enhanced DNA repair." },
    { hour: 56, message: "Fat adaptation is at its peak, providing stable energy." },
    { hour: 57, message: "Autophagy is targeting long-lived proteins for breakdown." },
    { hour: 58, message: "Your immune system is undergoing a reset and regeneration." },
    { hour: 59, message: "Inflammation reduction is supporting overall health improvements." },
    { hour: 60, message: "Growth hormone levels may have increased up to 10 times the normal rate." },
    { hour: 61, message: "Your body is experiencing maximum ketone production." },
    { hour: 62, message: "Cellular cleanup processes are operating at peak efficiency." },
    { hour: 63, message: "Stem cell production continues to increase." },
    { hour: 64, message: "Your body is breaking down the oldest cells for recycling." },
    { hour: 65, message: "Insulin sensitivity has significantly improved in all tissues." },
    { hour: 66, message: "Your body is in a deep state of ketosis and fat adaptation." },
    { hour: 67, message: "Autophagy is providing maximum anti-aging benefits." },
    { hour: 68, message: "Your immune system is undergoing significant regeneration." },
    { hour: 69, message: "Growth hormone is promoting optimal tissue repair and muscle preservation." },
    { hour: 70, message: "Your body is experiencing enhanced cognitive clarity and focus." },
    { hour: 71, message: "Cellular stress resistance is at its peak." },
    { hour: 72, message: "Amazing! Your body has undergone significant cellular repair and regeneration." }
  ];